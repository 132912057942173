import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  useToast,
  Flex,
  Box,
  Image,
  Text,
  Grid,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
} from '@chakra-ui/react';
import axios from 'axios';

const MyForm = () => {
  const [formData, setFormData] = useState({
    workflowId: '8420eb79-5a21-470b-814f-72b3025d5abe',
    data: {
      frontUrl: '',
      backUrl: '',
      docType: 'passport',
      dob: '',
      countryCode: '',
      firstName: '',
      lastName: '',
      gender: '',
      image1: '',
      image2: '',
      selfie: '',
      image_url: '',
      mode: 'AUTOMATED',
    },
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  const [results, setResults] = useState('');

  const toast = useToast();

  const filterEmptyFields = data => {
    const filteredData = {};
    Object.keys(data).forEach(key => {
      if (data[key] !== '') {
        filteredData[key] = data[key];
      }
    });
    return filteredData;
  };

  const handleChange = e => {
    setFormData({
      ...formData,
      data: {
        ...formData.data,
        [e.target.name]: e.target.value,
        ...(e.target.name === 'frontUrl' && { image1: e.target.value }),
        ...(e.target.name === 'selfie' && { image2: e.target.value }),
        ...(e.target.name === 'selfie' && { image_url: e.target.value }),
      },
    });
  };

  const headers = {
    Authorization:
      'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6InU1Q3NZU216VnlaU3o4aDUteHlERCJ9.eyJodHRwczovL292ZXJ3YXRjaC5idXJlYXUuaWQiOnsiZW1haWwiOiJnYW5lc2hfdGVzdEBidXJlYXUuaWQiLCJuaWNrbmFtZSI6ImdhbmVzaF90ZXN0IiwicGljdHVyZSI6Imh0dHBzOi8vcy5ncmF2YXRhci5jb20vYXZhdGFyL2MwMjJjOGI0MjU0NTgxY2M3NjE3MTI1ZTI2MGVkNzM2P3M9NDgwJnI9cGcmZD1odHRwcyUzQSUyRiUyRmNkbi5hdXRoMC5jb20lMkZhdmF0YXJzJTJGZ2EucG5nIiwidXNlcl9pZCI6ImF1dGgwfDYzNzc4NzZkMzljODk5YTNmMWIyYTQxNCIsInVzZXJfbmFtZSI6ImdhbmVzaF90ZXN0QGJ1cmVhdS5pZCJ9LCJpc3MiOiJodHRwczovL2FjY291bnRzLnByaXNtLmJ1cmVhdS5pZC8iLCJzdWIiOiJhdXRoMHw2Mzc3ODc2ZDM5Yzg5OWEzZjFiMmE0MTQiLCJhdWQiOlsiaHR0cHM6Ly9hcGkub3ZlcndhdGNoLnN0Zy5idXJlYXUuaWQiLCJodHRwczovL3ByaXNtLWJ1cmVhdS51cy5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNzAxODQyMTUxLCJleHAiOjE3MDE5Mjg1NTEsImF6cCI6IjY1WERsQnFLdlU0NHhOTVplUEcwd1I0b2NBbWU5cU1EIiwic2NvcGUiOiJvcGVuaWQgcHJvZmlsZSBlbWFpbCIsInBlcm1pc3Npb25zIjpbXX0.PIkoI5BRxUJyj4Br6N5YkSI4pfNaCUPVl90V3uBAc6lN2is7A4TaaaVC0uIW-3GSAhOb3wNcW8Fn-Aws1NP49z7XKoLddHXJh8x6Jjeh5Q2nk1JNotDy3sNBoIUSIzXn_iVrIfq_XpZMkSGpmnqhyZwiZvz7V9jaN6BbNoX0eNIKNA6qNb3v423y_67ZJlOuSBACfrN2_pRRsD-LMGV7SJbtz1ioqiedn7lzkir50IiALGUBEyXx9OJ-_ouxL0wzs0uUouEzp5YFH-aWG-lSZ0xVGl5izkInmPMGvfD0yAoEhYCU56_eU5Y9FE_Lw3PReg0-XXCYKRcD1S3Dv5d_kQ',
    'x-bureau-auth-org-id': 'org_5YLrIY6Wnjxt1l6n',
    'Content-Type': 'application/json',
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);

    const filteredData = filterEmptyFields(formData.data);

    try {
      const response = await axios.post(
        'https://api.overwatch.stg.bureau.id/transactions',
        { ...formData, data: filteredData },
        { headers }
      );
      if (response.data.statusCode === 200) {
        toast({
          title: 'Success',
          description: 'Transaction Executed Succesfully. Fetching Details....',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }

      const referenceId =
        response.data.services['Document Verification (PHL)'][
          'Global Document Verification Submit v2'
        ].response.referenceId;
      const transactionId = response.data.transactionId;

      if (referenceId && transactionId) {
        const additionalData = {
          referenceId: referenceId,
          transactionId: transactionId,
        };

        // Second API Call
        setTimeout(async () => {
          const secondResponse = await axios.post(
            'https://api.overwatch.stg.bureau.id/transactions',
            { ...formData, data: { ...additionalData, ...filteredData } },
            { headers }
          );

          setResults(secondResponse);
          handleReset();
          setIsSubmitting(false);
          toast({
            title: 'Success',
            description: 'Details Fetched Succesfully',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }, 7000);
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to submit form',
        status: 'error',
        duration: 10000,
        isClosable: true,
      });
    }
  };

  const handleReset = () => {
    setFormData({
      workflowId: '8420eb79-5a21-470b-814f-72b3025d5abe',
      data: {
        frontUrl: '',
        backUrl: '',
        front: '',
        docType: '',
        dob: '',
        countryCode: '',
        firstName: '',
        lastName: '',
        gender: '',
        selfie: '',
      },
    });
  };

  function getTransactionData() {
    if (results.data.workflowReleaseType === 'v2') {
      const processedData = JSON.parse(JSON.stringify(results.data));

      let processedService = {};
      Object.keys(processedData.services).forEach(serviceKeys => {
        processedService = {
          ...processedService,
          ...processedData.services[serviceKeys],
        };
      });

      let services = [];
      Object.keys(processedService).forEach(serviceKeys => {
        const serviceData = processedService[serviceKeys];
        // Filter out null or empty values within each service
        const filteredServiceData = Object.entries(serviceData)
          .filter(([_, value]) => value != null && value !== '')
          .reduce((obj, [key, val]) => {
            obj[key] = val;
            return obj;
          }, {});

        if (Object.keys(filteredServiceData).length > 0) {
          services = [
            ...services,
            { ...filteredServiceData, name: serviceKeys },
          ];
        }
      });

      return {
        ...processedData,
        services,
      };
    }
    return results;
  }

  function formatCamelCaseToTitle(string) {
    // Split the string at each uppercase letter
    const words = string.split(/(?=[A-Z])/);
    // Convert the first letter of each word to uppercase
    const capitalizedWords = words.map(
      word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    // Join the words into a string, separated by spaces
    return capitalizedWords.join(' ');
  }

  return (
    <>
      <Flex
        width="100vw"
        height="100%"
        mt="4"
        alignItems="center"
        justifyContent="center"
        direction="column"
      >
        <Box
          borderWidth="1px"
          w="6xl"
          borderRadius="lg"
          overflow="hidden"
          px="6"
          py="8"
        >
          <VStack as="form" onSubmit={handleSubmit} w="full">
            <Grid templateColumns="repeat(2, 1fr)" gap={6} w="full">
              <FormControl>
                <FormLabel>Document Front Side</FormLabel>
                <Input
                  name="frontUrl"
                  value={formData.data?.frontUrl}
                  onChange={handleChange}
                  placeholder="Image URL"
                  type="text"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Document Back Side</FormLabel>
                <Input
                  name="backUrl"
                  value={formData.data?.backUrl}
                  onChange={handleChange}
                  placeholder="Image URL"
                />
              </FormControl>
              <FormControl>
                <FormLabel>Selfie Image</FormLabel>
                <Input
                  name="selfie"
                  value={formData.data?.selfie}
                  onChange={handleChange}
                  placeholder="Image URL"
                />
              </FormControl>

              <FormControl>
                <FormLabel>First Name</FormLabel>
                <Input
                  name="firstName"
                  value={formData.data?.firstName}
                  onChange={handleChange}
                  placeholder="Enter your first name"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Last Name</FormLabel>
                <Input
                  name="lastName"
                  value={formData.data?.lastName}
                  onChange={handleChange}
                  placeholder="Enter your last name"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Gender</FormLabel>
                <Input
                  name="gender"
                  value={formData.data?.gender}
                  onChange={handleChange}
                  placeholder="Enter your gender"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Doctype</FormLabel>
                <Select
                  name="docType"
                  value={formData.data?.docType}
                  onChange={handleChange}
                >
                  <option value="passport">Passport</option>
                  <option value="dl">DL</option>
                  <option value="nationalId">National ID</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Date of Birth</FormLabel>
                <Input
                  name="dob"
                  value={formData.data?.dob}
                  onChange={handleChange}
                  placeholder="Enter your date of birth"
                />
              </FormControl>

              <FormControl>
                <FormLabel>Country Code</FormLabel>
                <Input
                  name="countryCode"
                  value={formData.data?.countryCode}
                  onChange={handleChange}
                  placeholder="Enter country code"
                />
              </FormControl>
            </Grid>
            <Flex mt="6" w="full" justify="center">
              <Button onClick={handleReset} colorScheme="gray">
                Reset
              </Button>
              <Button
                ml="2"
                w="140px"
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
              >
                Submit
              </Button>
            </Flex>
          </VStack>

          {results && (
            <Grid
              gridTemplateRows="1fr"
              gap="10px"
              w="full"
              h="100%"
              overflow="auto"
            >
              <Flex direction="column" mt={2} borderRadius="lg" pt="0">
                <Tabs
                  color="gray.600"
                  variant="unstyled"
                  onChange={index => setTabIndex(index)}
                  h="calc(100% - 160px)"
                >
                  <TabList pl={0} padding="5px" borderRadius="lg">
                    {getTransactionData()
                      .services.filter((_, index) =>
                        [0, 2, 3, 4].includes(index)
                      )
                      .map((service, index) => {
                        return (
                          <Tab
                            key={index}
                            pl="0"
                            _selected={{
                              borderBottom: 'none',
                              boxShadow: 'none',
                            }}
                            fontWeight="medium"
                            pb={0}
                            mr="2"
                          >
                            <Flex direction="column" width="fit-content" mt="4">
                              <Text
                                fontSize="md"
                                pb={2}
                                color={
                                  tabIndex === index ? 'blue.600' : 'gray.600'
                                }
                                fontWeight={
                                  tabIndex === index ? 'bold' : 'normal'
                                }
                              >
                                {service.name}
                              </Text>
                              <Box
                                w="34px"
                                borderWidth={2}
                                borderColor={
                                  tabIndex === index
                                    ? 'blue.600'
                                    : 'transparent'
                                }
                                bg={
                                  tabIndex === index
                                    ? 'blue.500'
                                    : 'transparent'
                                }
                                borderRadius="lg"
                                transition="all 100ms ease-in"
                              />
                            </Flex>
                          </Tab>
                        );
                      })}
                  </TabList>

                  <TabPanels p="0">
                    {getTransactionData()
                      ?.services.filter((_, index) =>
                        [0, 2, 3, 4].includes(index)
                      )
                      .map((service, serviceIndex) => (
                        <TabPanel key={serviceIndex} p="0">
                          <Box>
                            <Grid
                              gridTemplateColumns="1fr 1fr 1fr"
                              h="100%"
                              overflow="auto"
                            >
                              {service.response &&
                                Object.entries(service.response)
                                  .filter(s => s[0] !== 'error')
                                  .map(([serviceName, value], index) => {
                                    return (
                                      <Box
                                        key={serviceName}
                                        padding="5px 0"
                                        m="5px"
                                      >
                                        <Text
                                          size="sm"
                                          fontWeight="medium"
                                          color="gray.500"
                                        >
                                          {formatCamelCaseToTitle(serviceName)}
                                        </Text>
                                        <Text
                                          color="gray.700"
                                          fontWeight="bold"
                                        >
                                          {JSON.stringify(value)}
                                        </Text>
                                      </Box>
                                    );
                                  })}
                            </Grid>
                          </Box>
                        </TabPanel>
                      ))}
                  </TabPanels>
                </Tabs>
              </Flex>
            </Grid>
          )}
        </Box>

        <Flex direction="column" justify="center" align="center" mt="2" mb="2">
          <Text mb={1} color="gray.500" textAlign="center" fontSize="xs">
            Powered by
          </Text>
          <Image
            src="https://uploads-ssl.webflow.com/61a5d188c74343756e97d881/647bb473159f326b03a3c7e2_bureau_logohorizontal_bw_black%203.png"
            w="22"
            h="22"
          />
        </Flex>
      </Flex>
    </>
  );
};

export default MyForm;
